/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import { createLeadCRM, sendCourseDetailsPDF } from '../../services/utilsServices';
import { removeCountryCode } from '../presentation/payments/InstalmentForm';
import { utmClickCount } from '../../services/dashboardServices';
import { useRouter } from 'next/router';

const SyllabusForm = ({ open, setOpen, course }) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const btnCallback = document.querySelector('#btn_callback');
            const btnChat = document.querySelector('#btn_chat');
            const header = document.querySelector('#common_navbar');
            const bottomBar = document.querySelector('#bottomBar');

            if (open) {
                if (btnCallback) {
                    btnCallback.classList.add('kwn-cmp-hidden');
                }
                if (btnChat) {
                    btnChat.classList.add('kwn-cmp-hidden');
                }
                if (header) {
                    header.classList.add('kwn-cmp-hidden');
                }
                if (bottomBar) {
                    bottomBar.classList.add('kwn-cmp-hidden');
                }
            } else {
                if (btnCallback) {
                    btnCallback.classList.remove('kwn-cmp-hidden');
                }
                if (btnChat) {
                    btnChat.classList.remove('kwn-cmp-hidden');
                }
                if (header) {
                    header.classList.remove('kwn-cmp-hidden');
                }
                if (bottomBar) {
                    bottomBar.classList.remove('kwn-cmp-hidden');
                }
            }
        }
    }, [open]);


    // useEffect(() => {
    //     if (open && typeof window !== 'undefined') {
    //         const _container = document.querySelectorAll(".layout");
    //         if (_container.length) {
    //             _container[0].style.height = '100vh';
    //             _container[0].style.overflowY = 'hidden';
    //         }
    //     } else {
    //         const _container = document.querySelectorAll(".layout");
    //         if (_container.length) {
    //             _container[0].style.height = 'auto';
    //             _container[0].style.overflowY = 'auto';
    //         }
    //     }
    // }, [open])

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const modalHandle = () => {
        setOpen((prevState) => !prevState);
    };


    const router = useRouter();

    const { query } = router;

    const [user, setUser] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const [isMobileValid, setIsMobileValid] = useState(true);
    const [mobileFocus, setMobileFocus] = useState(false);

    const inputChangeHandle = (e) => {
        const { name, value } = e.target;
        setUser((prevState) => ({ ...prevState, [name]: value }));
    };

    const [mobile, setMobile] = useState({
        mobile: '',
        dialCode: '',
        country: '',
    });



    const submitHandle = async (e) => {
        e.preventDefault();

        if ((mobile.dialCode === '91' && mobile.mobile.length < 10) || !mobile.mobile.length) {
            setIsMobileValid(false);
            return;
        } else {
            setIsMobileValid(true);
        }

        if (user.name && validator.isEmail(user.email)) {
            const payload = {
                wsId: course?.id,
                name: user.name,
                mobile: `+${mobile.dialCode} ${mobile.mobile}`,
                email: user.email,
            };
            setSending(true);
            try {
                const resp = await sendCourseDetailsPDF(payload);
                handleEvent(user.name, `+${mobile.dialCode} ${mobile.mobile}`, user.email);
                setSending(false);

                if (resp.data.code === 200) {
                    setSuccess(true);
                }

                const kylasCRM = {
                    firstName: '',
                    lastName: user.name,
                    phoneNumbers: [
                        {
                            type: 'MOBILE',
                            code: mobile.country,
                            value: mobile.mobile,
                            dialCode: `+${mobile.dialCode}`,
                            primary: true,
                        },
                    ],
                    emails: [{ type: 'OFFICE', value: user.email, primary: true }],
                    products: [{ id: course?.kylasProductId }],
                    requirementName: course?.name,
                    campaign: 1098285,
                    source: 1098293,
                };

                const _jsonData = JSON.stringify(kylasCRM);
                createLeadCRM(_jsonData);
            } catch (error) {
                setSending(false);
                setSuccess(false);
            }
        } else {
            console.log('Failed');
        }

        if (query.id) {
            handleClickEvent(course.id, 'course page', 'button', 'brochure form filled', 21)
        }
    };

    useEffect(() => {
        if (!open) {
            setUser({
                email: '',
                name: '',
                phone: '',
            });
        }
    }, [open])


    const handleClickEvent = async (_wsId, _utmSource, _utmMedium, _utmCampaign, _utmType, _userId = null) => {
        const _payload = {
            wsId: _wsId,
            source: _utmSource,
            medium: _utmMedium,
            campaign: _utmCampaign,
            type: _utmType
        }
        if (_userId) {
            _payload.userId = _userId;
        }
        try {
            await utmClickCount(_payload)
        } catch (error) {
            console.log("Something went wrong::>");
        }
    }

    const handleEvent = (_name, _mobile, _email) => {
        window.gtag('event', 'brochure_form', {
            transaction_id: `K_${new Date().getTime()}`,
            value: 0,
            user_data: {
                email_address: _email,
                phone_number: _mobile,
                address: {
                    first_name: _name,
                },
            },
            event_category: 'brochure',
            event_label: 'brochure_form',
        });
    };

    useEffect(() => {
        if (open) {
            if (typeof window !== 'undefined') {
                document.body.style.overflowY = 'hidden';
            }
        } else {
            if (typeof window !== 'undefined') {
                document.body.style.overflowY = 'auto';
            }
        }
    }, [open]);

    const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave?';

        event.returnValue = message;
        return message;
    };

    useEffect(() => {
        if (user.name !== '' || user.email !== '') {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [user.email, user.name]);

    return (
        <div className='syllabus-popup'>
            <div className={`edu-search-popup ${open ? 'open' : ''}`}>
                <div className='download-form'>
                    <div className='header w-100 d-flex align-items-center justify-content-between'>
                        <div>
                            <div className='mobile-header'>
                                <p className='desc display-6 fw-bold text-dark'>Get the syllabus</p>
                                <span>Pinky promise, we won't spam you!</span>
                            </div>
                        </div>
                        <button className='bg-white border-0 btn-close' onClick={modalHandle}>
                            { }
                        </button>
                    </div>
                    <div className='row'>
                        {success ? (
                            <div className='col-lg-7 col-sm-12 form-section'>
                                <div className='mobile-bg-container my-3'>
                                    <img
                                        src='/kaarwan/backgrounds/course-curriculum-background-mobile.avif'
                                        alt='curriculum-background'
                                        loading='lazy'
                                        className='w-100'
                                    />
                                </div>
                                <div className='success-content'>
                                    <h4>
                                        Almost there! <br /> Check your inbox.
                                    </h4>
                                    <p>
                                        We just sent you the <span className='text-dark'>{course?.name}</span>{' '}
                                        curriculum on <span className='text-primary'>{user.email}</span>{' '}
                                    </p>

                                    <div className='d-flex align-items-center'>
                                        <img src='/kaarwan/icons/gmail-icon.png' alt='gmail' width='' height='' />
                                        <a
                                            href='https://mail.google.com/mail/u/0/?ogbl#inbox'
                                            className='gmail-link kw-orange-btn'
                                            target='_blank'
                                        >
                                            Open Gmail
                                            <i className='ri-external-link-line ri-lg'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='col-lg-7 col-sm-12 form-section'>
                                <div className='desktop-header'>
                                    <p className='desc display-4 fw-bold text-dark '>Get the syllabus</p>
                                    <span>Pinky promise, we won't spam you!</span>
                                </div>

                                <div className='mobile-bg-container my-3'>
                                    <img
                                        src='/kaarwan/backgrounds/course-curriculum-background-mobile.avif'
                                        alt='curriculum-background'
                                        loading='lazy'
                                        className='w-100'
                                    />
                                </div>
                                <form className='form-container' method='post' onSubmit={submitHandle}>
                                    <div className='input-wrapper form-group mb-4'>
                                        <label htmlFor='name' className='text-dark'>
                                            Full Name <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg'
                                            id='name'
                                            name='name'
                                            placeholder='e.g Akash shah'
                                            value={user.name}
                                            onChange={inputChangeHandle}
                                            required
                                        />
                                    </div>
                                    <div className='input-wrapper form-group mb-4'>
                                        <label htmlFor='email' className='text-dark'>
                                            Email address <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className='form-control form-control-lg'
                                            placeholder='e.g qwerty@gmail.com'
                                            value={user.email}
                                            onChange={inputChangeHandle}
                                            required
                                        />
                                    </div>
                                    <div className='input-wrapper form-group mb-4'>
                                        <label htmlFor='mobile' className='text-dark'>
                                            Mobile number <span className='text-danger'>*</span>
                                        </label>

                                        <div className='kwn-mobile-number-wrapper counselling-form syllabus-form mb-24'>
                                            <PhoneInput
                                                country='in'
                                                inputProps={{
                                                    name: 'mobile',
                                                    required: true,
                                                }}
                                                copyNumbersOnly
                                                containerClass={mobileFocus ? 'focus' : ''}
                                                countryCodeEditable={false}
                                                enableSearch
                                                onFocus={() => setMobileFocus(true)}
                                                onBlur={() => setMobileFocus(false)}
                                                onChange={(value, country) =>
                                                    setMobile({
                                                        dialCode: country.dialCode,
                                                        mobile: removeCountryCode(value, country.dialCode),
                                                        country: String(country.countryCode).toUpperCase(),
                                                    })
                                                }
                                            />

                                            <span className='text-danger d-block mt-1'>
                                                <small>{!isMobileValid && 'Enter your 10-digit Mobile number'}</small>
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        type='submit'
                                        name='btn-submit'
                                        className='kw-btn-orange border-0 text-light d-flex align-items-center w-100 justify-content-center btn-submit'
                                        disabled={sending}
                                    >
                                        {sending ? (
                                            <span className='dotted-loader'>
                                                <div className='spinner'>
                                                    <div className='bounce1'></div>
                                                    <div className='bounce2'></div>
                                                    <div className='bounce3'></div>
                                                </div>
                                            </span>
                                        ) : (
                                            <>
                                                Get Syllabus <i className='ri-arrow-right-line'></i>{' '}
                                            </>
                                        )}
                                    </button>
                                </form>
                            </div>
                        )}
                        <div className='col-lg-5 desktop-bg-container'>
                            <img
                                src='/kaarwan/backgrounds/course-curriculum-background-desktop.avif'
                                alt='curriculum-background'
                                className='w-100'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SyllabusForm;
