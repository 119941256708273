import React, { useEffect } from 'react'
import Lenis from 'lenis';

const SmoothScroller = () => {
    useEffect(() => {
        const lenis = new Lenis()

        // lenis.on('scroll', (e) => {
        //     console.log(e)
        // })

        function raf(time) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    }, [])
    return (
        <div></div>
    )
}

export default SmoothScroller