import http from './httpService';

/**************************************
  Kaarwan utilities APIs

  1. Send curriculum pdf ->Checked
  2. Get wsRedirect -> Checked
  3. LeadSquared lead capture -> Checked
  4. Get link for page 404 -> Checked
   
***************************************/

// Send Email Attached PDF for the selected course
export function sendCourseDetailsPDF(data) {
  return http.post('/api/course/curriculum/form', data);
}

// Handle previous broken link
export function getWsRedirect() {
  return http.get(`/api/ws/redirect`);
}

// LeadSquared lead capture api
export function createLeadCapture(data) {
  const baseUrl = process.env.NEXT_PUBLIC_LEAD_SQUARED_URL;
  const accessKey = process.env.NEXT_PUBLIC_LEAD_SQUARED_ACCESS_KEY;
  const secretKey = process.env.NEXT_PUBLIC_LEAD_SQUARED_SECRET_KEY;
  const leadCaptureUrl = `${baseUrl}?accessKey=u$${accessKey}&secretKey=${secretKey}`;
  if (baseUrl && accessKey && secretKey) {
    return http.post(leadCaptureUrl, data);
  }
}

// Kylas CRM API

export function createLeadCRM(data) {
  const apiKey = process.env.NEXT_PUBLIC_KYLAS_CRM_KEY;
  const crmURL = process.env.NEXT_PUBLIC_KYLAS_CRM_URL;
  http.post(crmURL, data, {
    headers: {
      'api-key': `${apiKey}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return;
}

// For page 404
export function getRedirectLink(data) {
  return http.post(`/api/kaarwan/redirect`, data);
}


// Intellectads
// PFB Image Pixel Code
export function createIntellectadzPFB(key) {
  return http.get(`https://track.intellect-adz.com/offr/conv.asp?cid=3092&type=1&key=${key}`)
}

// GTM Pixel Code
// export function createIntellectadzGTM(key) {
//   return http.get(`https://track.intellect-adz.com/offr/conv.asp?cid=3092&type=1&key=${key}`)
// }